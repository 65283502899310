<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    const path = this.$route.query.path;
    const index = this.$route.query.index;
    const id = this.$route.query.id;
    const cId = this.$route.query.cId;
    const name = this.$route.query.name;
    setTimeout(() => {
      this.$router.replace({ path, query: { index, id, cId, name } });
    }, 0);
  },
};
</script>

<style scoped lang="less"></style>
